import React, { useEffect, useState } from 'react'

// import NewsletterForm from '../../../components/Forms/NewsletterSignupForm'
import { OrangeIcon } from '../../../components/Icon'
import Modal from '../../../components/Modal'
import OmbudsmanForm from '../../../components/Forms/OmbudsmanForm'

import { Section, CardContact } from './style'
import useDataLayer from '../../../hooks/useDataLayer/dataLayerBody'

const Newsletter = () => {
  const [ visible, setVisible ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  function toggleVisible () {
    setVisible(!visible)
  }

  useEffect(() => {
    if (window.RDStationForms) {
      new window.RDStationForms('newsletter-site-duplicado-87db4ae21bc9135bac87', 'UA-130435268-1').createForm()
    }
  }, [])

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex justify-content-center justify-content-lg-between'>
          <div className='col-12 col-md-10 col-lg-6 col-xl-5'>
            <div role='main' id='newsletter-site-duplicado-87db4ae21bc9135bac87' />
          </div>
          <div className='col-12 col-lg-5 mt-5 mt-lg-0'>
            <CardContact>
              <span className='fs-20 lh-25 text-orange-medium fw-600'>Fale com a gente</span>
              <div className='d-flex align-items-center mt-4'>
                <div className='mr-2'>
                  <OrangeIcon color='#161616' icon='phone' size='MD' />
                </div>
                <div>
                  <span className='fs-14 lh-17 text-blue-900 fw-400 d-lg-block m-0'>Entre em contato pelo telefone</span>
                  <a
                    href='tel:+553133478012'
                    className='fs-14 lh-17 text-orange-medium fw-700 m-0'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_07',
                        section_name: 'Fale com a gente',
                        element_name: '+55 (31) 3347-8012',
                        element_action: 'click button',
                      })
                    }}
                  >
                    +55 (31) 3347-8012
                  </a>
                </div>
              </div>
              <div className='d-flex align-items-center mt-4'>
                <div className='mr-2'>
                  <OrangeIcon color='#161616' icon='email' size='MD' />
                </div>
                <div>
                  <span className='fs-14 lh-17 text-blue-900 fw-400 d-lg-block m-0'>Você também pode mandar um email para</span>
                  <a
                    href='mailto:ri@interasset.com.br'
                    className='fs-14 lh-17 text-orange-medium fw-700 m-0'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_07',
                        section_name: 'Você também pode mandar um email para',
                        element_name: 'ri@interasset.com.br',
                        element_action: 'click button',
                      })
                    }}
                  >
                    ri@interasset.com.br
                  </a>
                </div>
              </div>
            </CardContact>
            <Modal visible={visible} onClickOutside={toggleVisible} onClose={toggleVisible}>
              <OmbudsmanForm />
            </Modal>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Newsletter
