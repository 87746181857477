import styled from 'styled-components'
import { gray, orange } from '../../../styles/colors'
import breakpoints from '../../../styles/breakpoints'

export const Section = styled.section`

  .bricks--component-embeddable-form {
    width: 100% !important;

    h3 {

      strong {
        font-family: 'Inter', Arial, Helvetica, sans-serif !important;
        font-size: 20px !important;
        color: ${gray[750]} !important;
        line-height: 25px !important;

        @media (min-width: ${breakpoints.md}) {
          font-size: 24px !important;
          line-height: 30px !important;
        }

        @media (min-width: ${breakpoints.lg}) {
          font-size: 40px !important;
          line-height: 50px !important;
        }
      }
    }


    label {
      font-family: 'Inter', Arial, Helvetica, sans-serif !important;
      color: ${gray[750]} !important;
      font-size: 12px !important;
      font-weight: 700 !important;
    }

    input {
      background: ${gray[400]} !important;
      border-radius: 4px !important;
      border: 0 !important;
      font-size: 14px !important;
      padding: 0 10px !important;
      font-weight: 300 !important;
      height: 40px !important;
    }

    .rd-text {
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      p {
        font-family: 'Inter', Arial, Helvetica, sans-serif !important;
        
        span {
          color: ${gray[750]} !important;
        }
      }
    }

    .rd-button {
      border-radius: 0.3rem !important;
      font-size: 16px !important;
      background: ${orange.base} !important;
      height: 42px !important;
      font-family: 'Inter', Arial, Helvetica, sans-serif !important;
      font-weight: 600 !important;
    }

    #rd-column-l4g127k8 > div {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    #rd-column-l4g127kc > div {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
`

export const CardContact = styled.div`
  padding: 20px;
  border-radius: 8px;
  border: 2px solid #161616;

  button {
    background: none;
    outline: none;
    border: none;
    padding: 0;

    &:active {
      outline: none;
    }
  }
`
